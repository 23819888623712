<template>
    <div>
      <div class="searchBox">
        <div class="searchLeft">
        </div>
        <div class="searchRight">
            <el-upload action="#" :auto-upload="false" :show-file-list="false" accept=".xlsx, .xls" :on-change="handleTriggerFile">
                <el-button
                    size="medium"
                    type="warning"
                    icon="el-icon-plus"
                    >上传触发模版</el-button>
            </el-upload>
            <el-upload action="#" :auto-upload="false"  :show-file-list="false" accept=".xlsx, .xls" :on-change="handlemessageFile">
                <el-button
                    size="medium"
                    type="primary"
                    icon="el-icon-plus"
                    >上传消息模版</el-button>
            </el-upload>
            <el-button
              size="medium"
              type="warning"
              icon="el-icon-plus"
              @click="imgVisible = true"
              >上传图片</el-button
            >
        </div>
      </div>
  
      <el-table border :data="tableData" height="76vh" style="width: 99%">
        <el-table-column prop="id" label="id"></el-table-column>
        <el-table-column prop="type" label="触发事件"></el-table-column>
        <el-table-column prop="days" label="触发天数"></el-table-column>
        <el-table-column prop="triggerTime" label="间隔/秒"></el-table-column>
        <el-table-column prop="userId" label="触发话术用户id"></el-table-column>
        <el-table-column prop="templateId" label="触发模板id"></el-table-column>
        <el-table-column label="操作" width="240">
          <template slot-scope="scope">
            <el-button size="small" type="warning" @click="toMessage(scope.row)"
              >消息模版</el-button
            >
            <el-button size="small" type="primary" @click="toEdit(scope.row,'trigger')"
              >编辑</el-button
            >
            <el-button
              size="small"
              type="danger" 
              @click="delFunc(scope.row,'trigger')"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
      <!-- 文件信息展示 -->
      <el-dialog  :close-on-click-modal="false" title="文件信息" :visible.sync="confirmVisible" width="800px" top="5vh">
        <el-table border :data="confirmTableData" height="70vh" style="width: 99%">
            <template v-if="uploadType==='trigger'">
              <el-table-column prop="type" label="触发事件"></el-table-column>
              <el-table-column prop="days" label="触发天数"></el-table-column>
              <el-table-column prop="triggerTime" label="间隔/秒"></el-table-column>
              <el-table-column prop="userId" label="触发话术用户id"></el-table-column>
              <el-table-column prop="templateId" label="触发模板id"></el-table-column>
            </template>
            <template v-if="uploadType==='message'">
              <el-table-column prop="templateId" label="模板id"></el-table-column>
              <el-table-column prop="contentType" label="消息类型码"></el-table-column>
              <el-table-column prop="msg" label="消息内容" width="400px"></el-table-column>
              <el-table-column prop="timeLine" label="间隔时间秒"></el-table-column>
            </template>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirmVisible = false">取 消</el-button>
          <el-button type="primary" @click="uploadXlXSubmit">确定上传</el-button>
        </span>
      </el-dialog>
      <!-- 图片上传 -->
      <el-dialog  :close-on-click-modal="false" title="图片上传" :visible.sync="imgVisible" v-if="imgVisible" width="800px" top="5vh">
        <uploadImg :multiple="true" :limit="50" uploadApiUrl="/file/upload-new" :reqType="{ status:0 ,type:'IMAGE'}" @change="uploadImgChange" ></uploadImg>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="imgVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 查看消息模版 -->
      <el-dialog  :close-on-click-modal="false" title="消息模版" :visible.sync="messageVisible" width="800px" top="5vh">
        <el-table border :data="templateTableData" height="70vh" style="width: 99%">
          <el-table-column prop="templateId" label="模板id"></el-table-column>
          <el-table-column prop="contentType" label="消息类型码"></el-table-column>
          <el-table-column prop="msg" label="消息内容"></el-table-column>
          <el-table-column prop="timeLine" label="间隔时间秒"></el-table-column>
          <el-table-column label="操作" width="160">
            <template slot-scope="scope">
              <el-button size="small" type="primary" @click="toEdit(scope.row,'message')"
                >编辑</el-button
              >
              <el-button
                size="small"
                type="danger" 
                @click="delFunc(scope.row,'message')"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <pagination :totalNum="templateTotalNum" :currentPage.sync="templateSearchForm.pageNo" :pageSize.sync="templateSearchForm.pageSize" @paginationChange="getTemplateList"></pagination>
        <span slot="footer" class="dialog-footer">  
          <el-button @click="messageVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
      <!-- 编辑模版 -->
      <el-dialog  :close-on-click-modal="false" title="编辑触发模版" :visible.sync="editVisible" width="600px">
        <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
      >
          <template v-if="editType==='trigger'">
            <el-form-item label="触发事件" prop="type" :rules="rules.blurRule">
              <el-input
                placeholder="请输入内容"
                v-model="ruleForm.type"
              ></el-input>
            </el-form-item>
            <el-form-item label="触发天数" prop="days" :rules="rules.blurRule">
              <el-input
                placeholder="请输入内容"
                v-model="ruleForm.days"
              ></el-input>
            </el-form-item>
            <el-form-item label="间隔/秒" prop="triggerTime" :rules="rules.blurRule">
              <el-input
                placeholder="请输入内容"
                v-model="ruleForm.triggerTime"
              ></el-input>
            </el-form-item>
            <el-form-item label="触发话术用户id" prop="userId" :rules="rules.blurRule">
              <el-input
                placeholder="请输入内容"
                v-model="ruleForm.userId"
              ></el-input>
            </el-form-item>
          </template>
          <template v-if="editType==='message'">
            <el-form-item label="消息类型码" prop="contentType" :rules="rules.blurRule">
              <el-input
                placeholder="请输入内容"
                v-model="ruleForm.contentType"
              ></el-input>
            </el-form-item>
            <el-form-item label="消息内容" prop="msg" :rules="rules.blurRule">
              <el-input
                placeholder="请输入内容"
                v-model="ruleForm.msg"
              ></el-input>
            </el-form-item>
            <el-form-item label="间隔时间秒" prop="timeLine" :rules="rules.blurRule">
              <el-input
                placeholder="请输入内容"
                v-model="ruleForm.timeLine"
              ></el-input>
            </el-form-item>
          </template>
        </el-form>
        <span slot="footer" class="dialog-footer">  
          <el-button @click="editVisible = false">关 闭</el-button>
          <el-button type="primary" @click="submitEditFunc">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import * as XLSX from 'xlsx';
  import uploadImg from '@/components/uploadImg'
  import pagination from '@/components/pagination'
  export default {
    data() {
      return {
        totalNum: 0,
        templateTotalNum: 0,
        confirmVisible: false,
        imgVisible: false,
        messageVisible: false,
        editVisible: false,
        ruleForm: {
          id:"",
          name: "",
          description:"",
          img:"",
          category:""
        },
        searchForm: {
          templateId: "",
          pageNo: 1,
          pageSize: 10
        },
        templateSearchForm:{
          pageNo: 1,
          pageSize: 10
        },
        tableData: [],
        confirmTableData: [],
        templateTableData: [],
        uploadType: 'trigger',//trigger:触发模版 message:消息模版 image:图片
        editType: 'trigger',//trigger:触发模版 message:消息模版
        rules: {
          blurRule: [
            { required: true, message: "请输入", trigger: "blur" },
          ],
          changeRule: [
            { required: true, message: "请选择", trigger: "change" },
          ],
          typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
        },
      };
    },
    components:{uploadImg,pagination},
    created() {
      this.getTableList();
    },
    methods: {
      // 处理文件选择
      handleTriggerFile(e) {
        const excelFile = e.raw;
        if (!excelFile) {
          this.$message.error('文件获取失败');
          return;
        }
        this.uploadType = 'trigger';
        this.readExcel(excelFile);
      },
      handlemessageFile(e) {
        const excelFile = e.raw;
        if (!excelFile) {
          this.$message.error('文件获取失败');
          return;
        }
        this.uploadType = 'message';
        this.readExcel(excelFile);
      },
      handleData(data) {
        const headers = data[0].map(header => header.split('-')[0]);
        const result = data
          .slice(1)
          .filter(row => row.length > 0)
          .map(row => {
              const obj = {};
              headers.forEach((key, index) => {
                  obj[key] = row[index];
              });
              return obj;
          });
        return result;
      },
      readExcel(excelFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            this.confirmTableData = this.handleData(jsonData);
            this.confirmVisible = true;
          } catch (error) {
            console.error('解析错误:', error);
            this.$message.error('文件解析失败: ' + error.message);
          }
        };

        reader.onerror = (error) => {
          this.$message.error('文件读取失败: ' + error.target.error.message);
        };
        reader.readAsArrayBuffer(excelFile);
      },
      async uploadXlXSubmit(){
        let res = null
        if(this.uploadType === 'trigger'){
          res = await this.$http.userTalkTemplateSaveTrigger(this.confirmTableData)
        } else {
          res = await this.$http.userTalkTemplateSaveTemplate(this.confirmTableData)
        }
        if(res){
          this.$message.success("上传成功！");
          this.confirmVisible=false;
          this.getTableList();
        }
      },
      uploadImgChange(val){
        console.log(val)
      },
      toEdit(data,type) {
        //编辑
        this.editType = type;
        this.ruleForm = {...data};
        this.editVisible = true;
      },
      async submitEditFunc() {
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            let res = null
            if(this.editType === 'trigger'){
              res = await this.$http.userTalkTemplateUpdateTrigger({...this.ruleForm});
            } else {
              res = await this.$http.userTalkTemplateUpdateTemplate({...this.ruleForm});
            }
            if (res) {
              this.$message.success("操作成功！");
              if(this.editType === 'trigger'){
                this.getTableList();
              } else {
                this.getTemplateList();
              }
              this.editVisible = false;
              this.$refs.ruleForm.resetFields();
            }
          }
        });
      },
      async getTableList(val) {
        if(val) {
          this.searchForm.pageNo = 1
        }
        let res = await this.$http.userTalkTriggerList({...this.searchForm});
        if (res) {
          this.tableData = res.result.records;
          this.totalNum = res.result.totalCount;
        }
      },
      async getTemplateList() {
        let res = await this.$http.userTalkTemplateList({...this.templateSearchForm});
        if (res) {
          this.templateTableData = res.result.records;
          this.templateTotalNum = res.result.totalCount;
        }
      },
      toMessage(row) {
        this.templateSearchForm.templateId = row.templateId;
        this.getTemplateList();
        this.messageVisible = true;
      },
      delFunc(row,type) {
        this.$confirm(`确定要删除么？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = null
            if(type === 'trigger'){
              console.log(row)
              res = await this.$http.userTalkDelTrigger({templateId:row.templateId})
            } else {
              res = await this.$http.userTalkDelTemplate({id:row.id})
            }
            if (res) {
              this.$message.success("操作成功！");
              if(type === 'trigger'){
                this.getTableList();
              } else {
                this.getTemplateList();
              }
            }
          })
          .catch(() => {});
      },
    },
  };
  </script>
  
  <style scoped>
  
  </style>
  